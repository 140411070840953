import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useData } from '../context/datacontext';
//import { useDrawer } from '../shared/use-drawer';
mapboxgl.accessToken = 'pk.eyJ1Ijoiem9ycm80MyIsImEiOiJjbHJlajN1OTMxZThqMmlsZGhrZ3ZpY2dkIn0.HnDCIeouyIWFAVKsHby9Rg';

const MapboxMap = ({ onMapLoad, onClusterClick }) => {
    const mapContainerRef = useRef(null);
    //const { openDrawer, closeDrawer } = useDrawer();
    const data = useData(); // Data is obtained from context

    useEffect(() => {
        if (!data) return; // Guard against undefined data, but assumes data will not be null
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [55.21688423201965, 25.0573650603517], // Coordinates for Dubai
            zoom: 10.172753092679802,
            attributionControl: false,
            logoPosition: 'top-left',
            minZoom: 8,
            maxZoom: 14
        });
        map.on('load', function () {
            // Use context-provided data as a source for the map
            map.addSource('dubaiData', {
                type: 'geojson',
                data: data, // Directly using data from context
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 20,
                clusterProperties: {
                    sumQntl: ["+", ["case", ["has", "qntl5"], ["get", "qntl5"], 0]],
                }
            });

            if (onMapLoad) {
                onMapLoad(map);
            }

            // Assuming addLayers function is defined within useEffect or elsewhere
            // and it doesn't rely on fetched data
            addLayers();
        });
        function addLayers() {
            map.addLayer({
                id: 'clusters',
                type: 'circle', // Changed from 'symbol' to 'circle'
                source: 'dubaiData',
                filter: ['has', 'point_count'], // Filter for clustered points
                paint: { // Using 'paint' property for circle styling
                    // Use a 'step' expression to assign a color based on the average quantile
                    'circle-opacity': 0.8,
                    'circle-color': [
                        'step',
                        ['/', ['get', 'sumQntl'], ['get', 'point_count']],
                        '#67a9cf', 1.5, // Deep Blue for avgQntl < 1.5, signaling lower price points
                        '#2c7bb6', 2.5, // Sky Blue for avgQntl >= 1.5 and < 2.5, a slight warm-up
                        '#fdae61', 3.5, // Orange for avgQntl >= 2.5 and < 3.5, vibrant midpoint
                        '#f46d43', 4.5, // Darker Orange for avgQntl >= 3.5 and < 4.5, intensifying warmth
                        '#d73027' // Bright Red for avgQntl >= 4.5, highlighting the highest prices
                    ],
                    // Modify circle radius based on the 'point_count' to visually represent the cluster size
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['get', 'point_count'],
                        1, 20, // Smaller clusters have a radius of 20px
                        100, 50 // Larger clusters have a radius of 50px
                    ]
                }
            });
            map.addLayer({
                id: 'unclustered-points',
                type: 'circle',
                source: 'dubaiData',
                filter: ['!', ['has', 'point_count']], // Filter for non-clustered points
                paint: {
                    'circle-opacity': 1.0,
                    'circle-color': [
                        'step',
                        ['get', 'qntl5'], // Use 'qntl10' directly from properties
                        '#67a9cf', 1.5, // Deep Blue for qntl10 < 1.5
                        '#2c7bb6', 2.5, // Sky Blue for qntl10 >= 1.5 and < 2.5
                        '#fdae61', 3.5, // Orange for qntl10 >= 2.5 and < 3.5
                        '#f46d43', 4.5, // Darker Orange for qntl10 >= 3.5 and < 4.5
                        '#d73027'  // Bright Red for qntl10 >= 4.5
                    ],
                    'circle-radius': 10 // Set a constant radius for individual points
                }
            });
        }
        map.on('click', 'unclustered-points', (e) => {
            if (e.features.length > 0) {
                const feature = e.features[0];
    
                // Since it's a single feature, create an array with one element containing its properties
                const featureData = [{
                    qntl10: feature.properties.qntl10,
                    area: feature.properties.area,
                    project_number: feature.properties.project_number,
                    project_status: feature.properties.project_status,
                    developer: feature.properties.developer,
                    project_name: feature.properties.project_name,
    
                    price_per_sqft_str: feature.properties.price_per_sqft_str,
                    size_sqft_str: feature.properties.size_sqft_str,
                    bedrooms_str: feature.properties.bedrooms_str,
                    prop_type_str: feature.properties.prop_type_str,
                    price_str: feature.properties.price_str,
                    we_can_find: feature.properties.we_can_find

                }];
    
                // Pass the single feature data array to the MapResults component
                // openDrawer({
                //     view: <MapResults data={featureData} />,
                //     placement: 'right',
                // });
                onClusterClick(featureData)
            }
        });
        map.on('click', 'clusters', (e) => {
            if (e.features.length > 0) {
                const feature = e.features[0];
                var clusterId = feature.properties.cluster_id;
                map.getSource('dubaiData').getClusterLeaves(clusterId, Infinity, 0, function (err, leaves) {
                    if (err) {
                        return console.error('Error getting cluster leaves:', err);
                    }
                    // Create an array of data from leaves properties
                    const leavesData = leaves.map(leaf => ({
                        qntl10: leaf.properties.qntl10,
                        area: leaf.properties.area,
                        project_number: leaf.properties.project_number,
                        project_status: leaf.properties.project_status,
                        developer: leaf.properties.developer,
                        project_name: leaf.properties.project_name,
    
                        price_per_sqft_str: leaf.properties.price_per_sqft_str,
                        size_sqft_str: leaf.properties.size_sqft_str,
                        bedrooms_str: leaf.properties.bedrooms_str,
                        prop_type_str: leaf.properties.prop_type_str,
                        price_str: leaf.properties.price_str,
                        we_can_find: leaf.properties.we_can_find
                    }));
                    // Pass the array of data to the MapResults component
                    // openDrawer({
                    //     view: <MapResults data={leavesData} />,
                    //     placement: 'right',
                    // });
                    onClusterClick(leavesData)
                });
            }
        });
        map.addControl(new mapboxgl.NavigationControl());
        return () => map && map.remove();
    }, [data]);


    return <div ref={mapContainerRef} style={{ height: '80vh', width: '100%' }} />;
};

export default MapboxMap;
