import { Button, Title, ActionIcon } from 'rizzui';
import React, { useRef, useEffect } from 'react';
import { Badge } from 'rizzui'
import ProjectOverview from '../shared/project-overview';


export default function MapResults({ data, onClose }) {
    return (

        <div className="flex w-full flex-col">
            {data && (
            <div className="flex flex-col border-b border-b-gray-900 p-4">
                <h3 className="text-[20px] semi-bold">Results for selected map region</h3>
                <p className="text-sm text-gray-600">
                    {data.length === 1
                        ? "There is a single project selected"
                        : `There are ${data.length} projects available`}

                </p>
            </div>
            )}
            {data && (
                <div  className="mt-2 h-auto px-4">
                    {data.map((item, index) => (
                        <ProjectOverview key={index} index={index} item={item} />
                    ))}

                </div>
            )
            }


        </div>
    );
};


