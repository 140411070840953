import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  ComposedChart,
} from 'recharts';
import { formatNumber } from '../utils/format-number';


export default function ChartHBar({ data, units=''}) {
  return (
    <div className="w-full rounded-[10px] border border-gray-300 px-4 py-4 min-w-[300px]">
      <p className="text-gray-900 font-semibold text-center ">{data.title}</p>
      <div className={`w-full pt-1 ${data.chart_data && data.chart_data.length < 3 ? 'h-[10rem]' : 'h-[20rem]'}`}>

          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              layout="vertical"
              data={data.chart_data}
              className="[&_.recharts-tooltip-cursor]:fill-opacity-20 dark:[&_.recharts-tooltip-cursor]:fill-opacity-10 [&_.recharts-cartesian-axis-tick-value]:fill-gray-300 dark:[&_.recharts-cartesian-axis-tick-value]:fill-gray-700 [&_path.recharts-rectangle]:!stroke-none"
            >
              <XAxis
                type="number"
                axisLine={false}
                tickLine={false}
                hide={true}
              />
              <YAxis
                dataKey="name"
                type="category"
                axisLine={false}
                tickLine={false}
                style={{ fontSize: 13, fontWeight: 500 }}
                width={100}
                className="rtl:-translate-x-24 [&_.recharts-text]:fill-gray-700"
              />
              <Bar dataKey="total" barSize={28} radius={[50, 50, 50, 50]}>
                <LabelList
                  position="right"
                  dataKey="total"
                  content={(props) => (
                    <CustomizedLabel {...props} barLength={props.width} color={props.fill} units={units} />
                  )}
                />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        </div>
  );
}

function CustomizedLabel({ x, y, width, height, value, color,units, barLength }) {
  const radius = 10;
  const offset = barLength > 60 ? -10 : 50;
  const rectWidth = formatNumber(value).length > 5 ? 55: 45;
  return (
    <g>
      <rect
        x={x + width - 44 + offset}
        y={y + 4}
        width={rectWidth}
        height={height - 8}
        rx={radius}
        fill={barLength > 60  ? "#FFFFFF" : "none"} // Conditional fill
        stroke={barLength > 60  ? "none" : color} // Conditional stroke
        strokeWidth={barLength > 60  ? 0 : 2} // Adjust strokeWidth based on condition
      />
      <text
        y={y + 1 + height / 2}
        x={x + width - (rectWidth == 45 ? 24 : 20) +offset+3}
        fill="currentColor"
        className="text-[13px] font-medium text-gray-800 dark:text-gray-200"
        textAnchor="middle"
        dominantBaseline="middle"
      >
       {formatNumber(value) + units}
      </text>
    </g>
  );
}
