// Drawer.js
import React, { useRef, useEffect } from 'react';
import '../styles/drawer.css'; // Assuming we will define some CSS for animation

const Drawer = ({ isOpen, onClose, children, isStickyButton = false }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (!isOpen && divRef.current) {
      divRef.current.scrollTop = 0; // Scroll to the top
    }
  }, [isOpen]);
  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      <div ref={divRef} className="flex-1 overflow-y-auto">
        {children}
      </div>
      {isStickyButton && (
        <div className="sticky bottom-2 flex items-center px-4 justify-center gap-3 bg-white pt-2 dark:bg-gray-50">
          <button className="w-full bg-blue-500 text-white py-1 px-2 hover:bg-blue-700 rounded-md"
              onClick={onClose}>
              Back To Map
          </button>
        </div>
      )}
    </div>
  );
};

export default Drawer;
