import { useEffect, useState } from 'react';
import { RadioGroup, Radio } from 'rizzui';
import { initialFilterState, possibleValues } from './filterStateAtom';

const filter_name = 'price_category';

// Define the priceCategoryNames object without TypeScript type
const priceCategoryNames = {
  '1': 'Economy',
  '2': 'Standard',
  '3': 'Comfort',
  '4': 'Premium',
  '5': 'Luxury',
  'Any': 'Any',
};

export default function PriceCategoryFilter({ state, onValueChange }) {
  const [value, setValue] = useState(state);
  useEffect(() => {
    setValue(state); // Update local state when prop changes
  }, [state]);
  useEffect(() => {
    if (onValueChange) {
      onValueChange(filter_name, value);
    }
  }, [value, onValueChange]);

  return (
    <div className="border border-gray-300 p-4 rounded">
      <p>Price Category</p>
      <div className="mt-4">
        <RadioGroup
          value={value}
          setValue={setValue}
          className="grid grid-cols-2 gap-4"
        >
          {possibleValues[filter_name].map((value) => (
            <Radio
              key={value}
              label={<div className="flex items-center justify-between text-gray-800"> <span className="ml-2">{priceCategoryNames[value]}</span></div>}
              labelClassName="w-full"
              name="price_category"
              value={value}
            />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}
