import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from "./shared/header";
import { Title } from 'rizzui';
import TotalCard from './widgets/total_card';
import ChartPie from './widgets/chart_pie';
import TopTable from './widgets/top_table';
import ChartHBar from './widgets/chart_h_bar';
import MonthPickerDrawer from './widgets/month_picker';
import Drawer from './components/drawer';
import getSubsetExcludingFutureMonths, { monthNames } from './utils/month-report';
import CTADownButton from './shared/cta_down_button';
export default function ReportView() {

    const [data, setData] = useState(null); // It's fine to start as null if we guard against null access later
    console.log(getSubsetExcludingFutureMonths()[0].key)
    const [month, setMonth] = useState(getSubsetExcludingFutureMonths()[0].key);
    useEffect(() => {
        // Use template literals to dynamically set the file name based on the month state
        const url = `/api_report_${month}.json`;
        fetch(url)
            .then(response => response.json())
            .then(fetchedData => {
                setData(fetchedData);
            })
            .catch(error => console.log(error));
    }, [month]); // Re-run the effect if the month state changes
    const [isMonthPickerDrawerOpen, setMonthPickerDrawerOpen] = useState(false);
    const toggleMonthPickerDrawer = () => {
        setMonthPickerDrawerOpen(!isMonthPickerDrawerOpen);
    };
    const handleOnDatePicked = (m) => {
        setMonth(m)
        toggleMonthPickerDrawer();
    };
    return (
        <>
        <Helmet>
          <title>Dubai Real Estate Trends Report | RealtyScout</title>
          <meta name="description" content="Dive deep into Dubai's property market with our latest real estate trends report. Gain the insights you need to make informed investment decisions." />
        </Helmet>
        <main className="relative flex w-full flex-col max-w-[500px] min-h-screen mx-auto">
            <Header />

            <div>
                <Title
                    as="h2"
                    className="mb-4 mt-2 text-[22px] lg:text-2xl 4xl:text-[26px] text-center"
                >
                    Dubai Realty Trends
                </Title>
            </div>
            <div className="flex items-center justify-between gap-4 px-4 py-1 w-full">
                <div className="flex-grow text-center">
                    <Title
                        as="h2"
                        className="text-[22px] lg:text-2xl 4xl:text-[26px]"
                    >
                        {monthNames[month]}
                    </Title>
                </div>
                <button className="flex items-center bg-blue-500 text-white py-1 px-2 hover:bg-blue-700 rounded-md"
                    onClick={toggleMonthPickerDrawer}>
                    Choose Month
                </button>
            </div>

            {data ? (
                <div className="flex flex-col items-center justify-between gap-4 px-4 py-3">
                    <TotalCard data={data.total_investment} />
                    <TotalCard data={data.total_transactions} />
                    <TotalCard data={data.total_price_sq_unit} />
                    <TotalCard data={data.total_price} />
                    <TotalCard data={data.total_size} />


                    <ChartPie data={data.completion_chart} />
                    <ChartHBar data={data.complete_sq_m_chart} />
                    <ChartPie data={data.property_type_chart} />
                    <ChartHBar data={data.bedrooms_chart} units={"%"} />
                    <ChartHBar data={data.bedrooms_sq_m_chart} />
                    <ChartHBar data={data.bedrooms_price_chart} />

                    <TopTable data={data.projects_table} />
                    <ChartHBar data={data.project_sq_m_chart} />
                    <ChartHBar data={data.project_average_size} />

                    <TopTable data={data.developers_table} />
                    <ChartHBar data={data.developer_sq_m_chart} />
                    <ChartHBar data={data.developer_average_size} />

                    <TopTable data={data.locations_table} />
                    <ChartHBar data={data.location_sq_m_chart} />
                    <ChartHBar data={data.location_average_size} />

                    <div className="w-full rounded-[10px] border border-gray-300 px-4 py-4 min-w-[300px] mb-11">
                        <p className="text-gray-900 text-justify">
                            Your insights journey doesn't have to end here. If you're seeking more detailed analyses, specific data sets, or
                            <span className="font-semibold"> unique insights</span> into the Dubai real estate market, our team is ready to equip you with all the data you need. Let's dive deeper together.
                        </p>                    </div>

                </div>
            ) : (
                <p>Loading...</p>
            )}
            <CTADownButton/>
            <Drawer isOpen={isMonthPickerDrawerOpen} onClose={toggleMonthPickerDrawer}>
                <MonthPickerDrawer currentMonth={month} onDatePicked={handleOnDatePicked} onClose={toggleMonthPickerDrawer}></MonthPickerDrawer>
            </Drawer>
        </main>
        </>
    );
}
