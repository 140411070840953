import React from 'react'; // Ensure React is imported when using JSX
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const COLORS = ['#2B7F75', '#FFD66B', '#64CCC5', '#176B87'];

export default function ChartPie({ data }) { // Correctly destructure props
  const chartData = data.chart_data || []; // Use provided data or fallback to an empty array
  return (
    <div className="w-full rounded-[10px] border border-gray-300 px-4 py-4 min-w-[300px]"> {/* Corrected min-w-[300 px] to min-w-[300px] */}
      <p className="text-gray-900 font-semibold text-center ">{data.title}</p>
      <div className="h-full items-center gap-4 sm:flex"> {/* Corrected responsive prefixes */}
        <div className="relative h-[300px] w-full sm:w-3/5 sm:py-3"> {/* Corrected responsive prefixes */}
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData} // Use dynamic data
                cornerRadius={10}
                innerRadius={55}
                outerRadius={100}
                paddingAngle={5}
                stroke="rgba(0,0,0,0)"
                dataKey="value"
              >
                {chartData.map((entry, index) => ( // Use dynamic data for cells
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="sm:w-2/5 sm:pl-2"> {/* Corrected responsive prefixes */}
          {chartData.map((entry, index) => ( // Use dynamic data for details
            <Detail
              key={index}
              color={COLORS[index % COLORS.length]}
              value={entry.value}
              text={entry.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Detail({ color, value, text }) { // Destructure parameters from a props object
  return (
    <div className="flex justify-between gap-2 border-b border-gray-100 py-3 px-4 last:border-b-0">
      <div className="flex items-center justify-start gap-1.5">
        <span style={{ backgroundColor: color }} className="block h-3 w-3 rounded" /> {/* Corrected style property */}
        <p className="text-gray-500">{text}</p>
      </div>
      <span
        style={{ borderColor: color }}
        className="rounded-full border-2 px-2 font-semibold text-gray-700"
      >
        {value}%
      </span>
    </div>
  );
}