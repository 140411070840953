import React, { useState, useEffect, useCallback } from 'react';
import '../styles/drawer.css';
import { Button, Title, ActionIcon } from 'rizzui';
import StatusFilter from './status-filter';
import PropertyFilter from './property-filter';
import BedroomsFilter from './bedrooms-filter';
import { filterGeoJSON } from './applyfilter';
import PriceCategoryFilter from './price-category-filter';
import { possibleValues, initialFilterState } from './filterStateAtom';
import { useData } from '../context/datacontext';

export default function FilterDrawerView({ onDataFiltered, onClose }) {
  const data = useData();
  //const [mapInstance, setMapInstance] = useState(null);
  // useEffect(() => {
  //   if (map) setMapInstance(map);
  // }, [map]);


  const [filterState, setFilterState] = useState(initialFilterState);
  const handleFilterValuesChange = useCallback((filterKey, newValues) => {
    setFilterState(prevState => ({ ...prevState, [filterKey]: newValues }));
  }, []);

  const resetFilter = useCallback((newValuesObject) => {
    setFilterState(prevState => ({ ...prevState, ...newValuesObject }));
  }, []);

  const resetAll = () => {
    resetFilter(initialFilterState)
    onDataFiltered(data,initialFilterState)
  }
  const filterJson = () => {
    if (data !== null) {
      const filteredData = filterGeoJSON(data, filterState);
      onDataFiltered(filteredData,filterState)
    } else {
      console.log("no geojson");
    }
  };

  // const resetAll = () => {
  //   resetFilter(initialFilterState)
  //   if (data !== null) {
  //     const filteredData = filterGeoJSON(data, initialFilterState);
  //     if (mapInstance) {
  //       const source = mapInstance.getSource('dubaiData');
  //       if (source && source.type === 'geojson') {
  //         source.setData(filteredData);
  //         mapInstance.flyTo({
  //           center: [55.238813879910396, 25.1097772109556], // Longitude, Latitude
  //           zoom: 10.172753092679802
  //         });
  //       }
  //     }
  //   } else {
  //     console.log("no geojson");
  //   }    
  // }


  // const filterJson = () => {
  //   if (data !== null) {
  //     const filteredData = filterGeoJSON(data, filterState);
  //     if (mapInstance) {
  //       const source = mapInstance.getSource('dubaiData');
  //       if (source && source.type === 'geojson') {
  //         (source).setData(filteredData);
  //         mapInstance.flyTo({
  //           center: [55.238813879910396, 25.1097772109556], // Longitude, Latitude
  //           zoom: 10.172753092679802
  //         });
  //       }
  //     }
  //     console.log(filteredData);
  //   } else {
  //     console.log("no geojson");
  //   }
  // };

  return (
    <div className="relative flex h-full w-full flex-col bg-white px-5 py-3.5 dark:bg-gray-50">
      <div className="mx-5 mb-6 flex items-center justify-between border-b border-muted px-4 pb-4">
      <h2 className="text-[20px] font-bold">Use Filters</h2>
        <button className="flex items-center bg-gray-500 text-white py-1 px-2 hover:bg-blue-700 rounded-md"
          onClick={ () => { onClose();}}>
            
           Close
        </button>
      </div>
      <div className="relative flex h-full w-full overflow-y-auto flex-col bg-white dark:bg-gray-50">
        <div className="@container">
          <div className="grid grid-cols-1 gap-3 lg:grid-cols-1 3xl:gap-8">
            <StatusFilter state={filterState['project_status']} onValueChange={handleFilterValuesChange} />
            <PropertyFilter state={filterState['property_type']} onValueChange={handleFilterValuesChange} />
            <BedroomsFilter state={filterState['bedrooms']} onValueChange={handleFilterValuesChange} />
            <PriceCategoryFilter state={filterState['price_category']} onValueChange={handleFilterValuesChange} />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-3 bg-white pt-2 dark:bg-gray-50">

        <button className="flex-shrink-0 bg-gray-500 text-white py-1 px-2 hover:bg-gray-700 rounded-md"
          onClick={ () => { resetAll();}}>
           Reset All
        </button>

        <button className="w-full bg-blue-500 text-white py-1 px-2 hover:bg-blue-700 rounded-md"
          onClick={ () => { filterJson();}}>
           Show results
        </button>
      </div>
    </div>
  );
}
