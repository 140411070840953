import React from 'react';
import TableIcon from '../icons/table_icon';
import MapIcon from '../icons/map_icon';
import ReportIcon from '../icons/report_icon';
import { ActionIcon } from 'rizzui'
import { useNavigate } from 'react-router-dom';
export default function Header() {
    const navigate = useNavigate();
    return (
<header className="flex w-full items-center justify-between p-3 border-b border-gray-200 bg-white">
    <div className="flex items-center" onClick={() => navigate('/')}>
        <img src="logo.png" alt="Logo" className="w-10 h-10" /> {/* Adjust the src attribute to your image's path */}
        <div className="flex flex-col ml-2">
            <h2 className="text-20px font-bold font-secondary">RealtyScout App</h2>
            <p className="text-sm text-gray-600">Estate Analytics Dubai</p>
        </div>
    </div>

    {/* Icons on the right */}
    <div className="ml-auto flex shrink-0 items-center gap-2 text-gray-500 xs:gap-3 xl:gap-4">
        <ActionIcon
            aria-label="Notification"
            variant="text"
            className="flex items-center justify-center relative h-[34px] w-[34px] shadow backdrop-blur-md dark:bg-gray-100 md:h-9 md:w-9"
            onClick={() => navigate('/reportview')}
        >
            <ReportIcon className="h-[20px] w-auto" />
        </ActionIcon>
        <ActionIcon
            aria-label="Notification"
            variant="text"
            className="flex items-center justify-center relative h-[34px] w-[34px] shadow backdrop-blur-md dark:bg-gray-100 md:h-9 md:w-9"
            onClick={() => navigate('/tableview')}
        >
            <TableIcon className="h-[20px] w-auto" />
        </ActionIcon>
        <ActionIcon
            aria-label="Notification"
            variant="text"
            className="flex items-center justify-center relative h-[34px] w-[34px] shadow backdrop-blur-md dark:bg-gray-100 md:h-9 md:w-9"
            onClick={() => navigate('/mapview')}
        >
            <MapIcon className="h-[20px] w-auto" />
        </ActionIcon>
        {/* <ActionIcon
            aria-label="Notification"
            variant="text"
            className="flex items-center justify-center relative h-[34px] w-[34px] shadow backdrop-blur-md dark:bg-gray-100 md:h-9 md:w-9"
        >
            <SettingsIcon className="h-[20px] w-auto" />
        </ActionIcon> */}
    </div>
</header>




    );
}
