import React from 'react';
import { Badge } from 'rizzui'
import { FaWhatsapp } from 'react-icons/fa'


function trimToFirstFourWords(sentence) {
    // Split the sentence into words
    const words = sentence.split(' ');

    // Take the first four words
    const firstFourWords = words.slice(0, 5);

    // Join the first four words back into a string
    return firstFourWords.join(' ');
}
function getText(txt) {
    const phrases = ["In this project, properties are available, with prices per square meter __we_can__.",
        "Properties in this project can be sourced, with prices per square meter __we_can__.",
        "We have access to properties within this project, with prices per square meter __we_can__.",
        "Explore properties in this project offering prices per square meter __we_can__.",
        "This project features properties, with prices per square meter __we_can__.",
        "Discover properties in this project, where prices per square meter range __we_can__.",
        "This project presents properties, with prices per square meter __we_can__.",
        "Options exist for properties in this project, with prices per square meter __we_can__.",
        "Identify properties in this project, with prices per square meter __we_can__.",
        "Securing properties in this project is achievable, with prices per square meter __we_can__.",
        "Uncover properties within this project, featuring prices per square meter __we_can__.",
        "In this project, properties are attainable, with prices per square meter __we_can__.",
        "Availability of properties in this project includes prices per square meter __we_can__.",
        "Seek properties in this project, with prices per square meter __we_can__, for exclusive living.",
        "Potential properties in this project are listed, with prices per square meter __we_can__.",
        "This project offers properties, with prices per square meter __we_can__, to interested buyers.",
        "You can locate properties in this project, with prices per square meter __we_can__.",
        "Opportunities for properties in this project exist, with prices per square meter __we_can__.",
        "Consider properties in this project, with prices per square meter __we_can__, for your next investment.",
        "Properties in this project are on offer, with prices per square meter __we_can__, catering to varied preferences."]
    const randomIndex = Math.floor(Math.random() * phrases.length);
    const randomElement = phrases[randomIndex];
    return randomElement.replace(" __we_can__", " " + txt)
}
function EnquiryButton({ projectNumber }) {
    const message = `Hello, I'm interested in the project with ID ${projectNumber}. Could you please provide more details?`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/971544336915?text=${encodedMessage}`;

    return (
        <button
            className="bg-blue-700 border border-blue-700 text-white px-4 py-1 text-sm flex items-center gap-2 hover:bg-blue-500 rounded-full"
            onClick={() => window.open(whatsappUrl, '_blank')}
        >
            Inquire
            <FaWhatsapp className="text-white" />
        </button>
    );
}
export default function ProjectOverview({ index, item }) {
    return (
        <div key={index} className="mb-4">
            <div className="pt-4 border border-gray-200 rounded">
                <div className="flex flex-col gap-3 p-2">
                    <div className="flex justify-between items-center">
                        <div className="flex-1 mr-2 font-semibold">
                            <span className="inline-block">{trimToFirstFourWords(item.project_name)}</span>
                        </div>
                        <Badge
                            color={item.project_status === 'Completed' ? 'success' : 'warning'}
                            rounded="md"
                            className="px-2"
                        >
                            {item.project_status}
                        </Badge>
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold text-gray-900">Developer:  </span> {trimToFirstFourWords(item.developer)}
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold text-gray-900">Area:  </span>{trimToFirstFourWords(item.area)}
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold text-gray-900">Price level:</span> <span className="font-bold">{item.qntl10} of 10</span>
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold text-gray-900">Type:  </span> <span>{item.prop_type_str}</span>
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold text-gray-900">Bedrooms:  </span>{item.bedrooms_str}
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold text-gray-900">Property area:  </span>{item.size_sqft_str}
                    </div>
                    <div className="text-sm">
                        <span className="font-semibold text-gray-900">Prices:  </span>{item.price_str}
                    </div>
                    <div className="flex flex-col space-y-2 items-end pb-4">
                        <span className="text-gray-900 text-sm">{getText(item.we_can_find)}</span>
                        <EnquiryButton projectNumber={item.project_number} />
                    </div>

                </div>
            </div>
        </div>


    )
}