import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from "./shared/header"
import { PiSliders } from 'react-icons/pi';
import MapboxMap from './components/mapboxmap'
import FilterDrawerView from './filters/filter-drawer-view';
import MapResults from './filters/mapresults';
import Drawer from './components/drawer';
import CTADownButton from './shared/cta_down_button';
export default function MapView() {

  const [clusterData, setClusterData] = useState(null)

  const [mapInstance, setMapInstance] = useState(null);

  const handleMapLoad = (map) => {
    setMapInstance(map);
  };
  const handleClusterClick = (clData) => {
    setClusterData(clData);
    toggleResultDrawer();
  };
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [isResultDrawerOpen, setResultDrawerOpen] = useState(false);

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };
  const toggleResultDrawer = () => {
    setResultDrawerOpen(!isResultDrawerOpen);
  };
  const handleDataFiltered = (fData) => {
    if (mapInstance) {
      const source = mapInstance.getSource('dubaiData');
      if (source && source.type === 'geojson') {
        source.setData(fData);
        mapInstance.flyTo({
          center: [55.21688423201965, 25.0573650603517], // Longitude, Latitude
          zoom: 10.172753092679802
        });
      }
    }
    toggleFilterDrawer();
  };
  return (
    <>
      <Helmet>
        <title>Find Projects on Map | RealtyScout Dubai</title>
        <meta name="description" content="Locate your next investment on our interactive map. Explore Dubai's real estate projects in detail and find properties that match your criteria." />
      </Helmet>
    <main className="relative flex w-full flex-col max-w-[500px] min-h-screen mx-auto">
      <Header></Header>
      <div className="flex items-center justify-between gap-4 px-4 py-3">
        {/* Left side container for text and indicators on the same line */}
        <div>
          <p className='font-bold mb-2 text-gray-600'>Zoom, Move, Select & Click</p>
          <div className="flex items-center gap-2">
            {/* Cheapest indicator */}
            <div className="flex items-center gap-2">
              <span className="text-xs">Cheapest</span>
              <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#67a9cf' }}></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full" style={{ backgroundColor:  '#2c7bb6' }}></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#fdae61' }}></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#f46d43' }}></div>
            </div>
            {/* Expensiest indicator */}
            <div className="flex items-center gap-2">
              <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#d73027' }}></div>
              <span className="text-xs">Expensiest</span>
            </div>
          </div>
        </div>

        {/* Right side button */}
        <button className="flex items-center bg-blue-500 text-white py-1 px-2 hover:bg-blue-700 rounded-md"
          onClick={toggleFilterDrawer}>
          <PiSliders className="h-5 w-5 mr-1 md:mr-2" /> Filters
        </button>
      </div>


      <div className="mt-2">
        <MapboxMap onMapLoad={handleMapLoad} onClusterClick={handleClusterClick} />
      </div>
      <Drawer isOpen={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
        <FilterDrawerView onDataFiltered={handleDataFiltered}  onClose={toggleFilterDrawer}></FilterDrawerView>
      </Drawer>
      <Drawer isOpen={isResultDrawerOpen} onClose={toggleResultDrawer} isStickyButton={true}>
        <MapResults data={clusterData} onClose={toggleResultDrawer} ></MapResults>
      </Drawer>
      <CTADownButton/>
    </main>
</>

  );
}
