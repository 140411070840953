import { useEffect, useState } from 'react';
import { RadioGroup, Radio } from 'rizzui';

import { initialFilterState, possibleValues } from './filterStateAtom';

const filter_name = 'bedrooms';

export default function BedroomsFilter({ state, onValueChange }) {
    const [value, setValue] = useState(state);
    useEffect(() => {
        setValue(state); // Update local state when prop changes
      }, [state]);
    useEffect(() => {
        if (onValueChange) {
            onValueChange(filter_name, value);
        }
    }, [value, onValueChange]);

    return (
        <div className="border border-gray-300 p-4 rounded">
            <p>Number of Bedrooms</p>
            <div className="mt-4">
                <RadioGroup
                    value={value}
                    setValue={setValue}
                    className="grid grid-cols-2 gap-4"
                >
                    {possibleValues[filter_name].map((status, index) => (
                        <Radio
                            key={status}
                            label={
                                <div className={`flex items-center justify-between text-gray-800 ${index === 2 ? 'col-span-2' : ''}`}>
                                     <span className="ml-2">{status}</span>
                                </div>
                            }
                            labelClassName="w-full"
                            name="bedrooms"
                            value={status}
                        />
                    ))}
                </RadioGroup>
            </div>
        </div>
    );
}
