import { atom } from 'jotai';

export const possibleValues = {
  project_status: ['Completed', 'Off-Plan', 'Any'],
  property_type: ['Apartment', 'Villa', 'Any'],
  bedrooms: ['Studio', '1', '2', '3', '4+', 'Any'],
  price_category: ['1', '2', '3', '4', '5', 'Any']
};

export const initialFilterState = {
  project_status: 'Any',
  property_type: 'Any',
  bedrooms: 'Any',
  price_category: 'Any'
};
