import './App.css';
import { DataProvider } from './context/datacontext';
import MapView from './mapview';
import TableView from './tableview';
import Landing from './landing';
import ReportView from './reportview';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/mapview" element={<MapView />} />
          <Route path="/tableview" element={<TableView />} />
          <Route path="/reportview" element={<ReportView />} />
        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;