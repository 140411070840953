import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from "./shared/header";
import { useData } from './context/datacontext';
import { Title } from 'rizzui';
import { Button, Input } from 'rizzui';
import { PiMagnifyingGlassBold, PiSliders } from 'react-icons/pi';
import Drawer from './components/drawer';
import FilterDrawerView from './filters/filter-drawer-view';
import ProjectOverview from './shared/project-overview';
export default function TableView() {
  const priceCategoryNames = {
    '1': 'Economy',
    '2': 'Standard',
    '3': 'Comfort',
    '4': 'Premium',
    '5': 'Luxury',
    'Any': 'Any',
  };

  const data = useData();
  const [visibleCount, setVisibleCount] = useState(30);
  const [searchText, setSearchText] = useState('');
  const [drawerFilteredData, setDrawerFilteredData] = useState(null)
  const [drawerFilterState, setDrawerFilterState] = useState(null)
  const [combinedFilteredData, setCombinedFilteredData] = useState([]);

  // Creating a ref for the input field to auto-focus
  const inputRef = useRef(null);

  useEffect(() => {
    // Resetting visibleCount whenever searchText changes
    setVisibleCount(30);

    let textFilteredData = data && searchText.length > 1
      ? data.features.filter(item =>
        item.properties.project_name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.properties.developer.toLowerCase().includes(searchText.toLowerCase()) ||
        item.properties.area.toLowerCase().includes(searchText.toLowerCase())
      )
      : data ? data.features : [];

    let newCombinedFilteredData;
    if (drawerFilteredData) {
      // Finding intersection between textFilteredData and drawerFilteredData based on project_number
      newCombinedFilteredData = textFilteredData.filter(item1 =>
        drawerFilteredData.some(item2 => item2.properties.project_number === item1.properties.project_number)
      );
    } else {
      // If there is no drawerFilteredData, just use the textFilteredData
      newCombinedFilteredData = textFilteredData;
    }

    setCombinedFilteredData(newCombinedFilteredData);
  }, [searchText, drawerFilteredData, data]);


  const handleDataFiltered = (fData, filterState) => {
    setDrawerFilteredData(fData.features)
    setDrawerFilterState(filterState)
    toggleFilterDrawer();
  };
  const getFilterParamString = () => {
    let result = 'Filter paramaters:'
    let count = 0
    if (drawerFilterState) {
      if (drawerFilterState.project_status !== 'Any')
        result += " Status: " + drawerFilterState.project_status + " |";
      if (drawerFilterState.property_type !== 'Any')
        result += (count++ > 0 ? " |" : "") + " Type: " + drawerFilterState.property_type;
      if (drawerFilterState.bedrooms !== 'Any')
        result += (count++ > 0 ? " |" : "") + " Bedrooms: " + drawerFilterState.bedrooms;
      if (drawerFilterState.price_category !== 'Any')
        result += (count++ > 0 ? " |" : "") + " Price category: " + priceCategoryNames[drawerFilterState.price_category];
    }
    if (searchText.length > 1)
      result += (count++ > 0 ? " |" : "") + " Text contains: " + searchText;
    return result;

  }
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!isFilterDrawerOpen);
  };

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  //   // Resetting visibleCount whenever searchText changes
  //   setVisibleCount(30);
  // }, [searchText, drawerFilteredData]);


  const loadMore = () => {
    setVisibleCount(prevCount => prevCount + 30);
  };

  return (
    <>
      <Helmet>
        <title>Explore Dubai Real Estate Projects Database | RealtyScout</title>
        <meta name="description" content="Explore all Dubai real estate projects with RealtyScout. Effortlessly filter through our comprehensive database to find your perfect property match today." />
      </Helmet>
    <main className="relative flex w-full flex-col max-w-[500px] min-h-screen mx-auto">
      <Header />
      <div>
        <Title
          as="h2"
          className="mb-4 mt-4 text-[22px] lg:text-2xl 4xl:text-[26px] text-center"
        >
          Advanced Project Search
        </Title>
      </div>
      <div className="flex items-center justify-between gap-4 px-4 py-3">
        <Input
          variant="flat"
          value={searchText}
          ref={inputRef}
          onChange={e => setSearchText(e.target.value)}
          placeholder="Project, developer, location"
          className="flex-1"
          prefix={<PiMagnifyingGlassBold className="h-[18px] w-[18px] text-gray-600" />}
          suffix={searchText && (
            <Button
              size="sm"
              variant="text"
              className="h-auto w-auto px-0"
              onClick={e => {
                e.preventDefault();
                setSearchText('');
              }}
            >
              Clear
            </Button>
          )}
        />
        <button className="flex items-center bg-blue-500 text-white py-1 px-2 hover:bg-blue-700 rounded-md"
          onClick={toggleFilterDrawer}>
          <PiSliders className="h-5 w-5 mr-1 md:mr-2" /> Filters
        </button>
      </div>
      {data && (
        <div className="mt-2 h-auto px-4">
          <div className="pb-4">
            <p className="text-sm text-gray-600">
              {combinedFilteredData.length !== data.features.length ? `Search results: ${combinedFilteredData.length} projects.` : `Total projects: ${data.features.length}`}
            </p>
            <p className="text-sm text-gray-600">
              {combinedFilteredData.length !== data.features.length ? getFilterParamString() : ''}
            </p>
          </div>
          {combinedFilteredData.slice(0, visibleCount).map((item, index) => (
              <ProjectOverview key={index} index={index} item={item.properties} />
          ))}
          {combinedFilteredData.length > visibleCount && (
            <button
              className="mt-4 mb-8 bg-blue-500 text-white px-2 py-1 text-sm rounded hover:bg-blue-600 mx-auto block"
              onClick={loadMore}
            >
              Load More
            </button>
          )}
        </div>
      )}
      <Drawer isOpen={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
        <FilterDrawerView onDataFiltered={handleDataFiltered} onClose={toggleFilterDrawer}></FilterDrawerView>
      </Drawer>
    </main>
    </>
  );
}
