export const monthNames = {
    'december2024': 'December 2024',
    'november2024': 'November 2024',
    'october2024': 'October 2024',
    'september2024': 'September 2024',
    'august2024': 'August 2024',
    'july2024': 'July 2024',
    'june2024': 'June 2024',
    'may2024': 'May 2024',
    'april2024': 'April 2024',
    'march2024': 'March 2024',
    'february2024': 'February 2024',
    'january2024': 'January 2024',
    'december2023': 'December 2023',
    'november2023': 'November 2023',
    'october2023': 'October 2023',
    'september2023': 'September 2023',
    'august2023': 'August 2023',
    'july2023': 'July 2023',
    'june2023': 'June 2023',
    'may2023': 'May 2023',
    'april2023': 'April 2023',
    'march2023': 'March 2023',
    'february2023': 'February 2023'
};
export default function getSubsetExcludingFutureMonths() {
    const currentDate = new Date();
    const selectedMonths = Object.keys(monthNames).filter(key => {
        const [month, year] = key.match(/\D+|\d+/g); // Split key into month name and year
        const lastDayOfMonth = new Date(year, new Date(`${month} 1, ${year}`).getMonth() + 1, 0); // Get the last day of the month
        
        // Check if the last day of the month is at least 5 days before the current date
        return (currentDate - lastDayOfMonth) / (1000 * 60 * 60 * 24) >= 5;
    }).map(key => ({
        key: key,
        name: monthNames[key]
    }));

    return selectedMonths;
}