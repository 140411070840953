import { FaWhatsapp } from 'react-icons/fa'

export default function CTADownButton() {
    const whatsappUrl = "https://wa.me/971544336915?text=Hello%2C%20I%27m%20exploring%20options%20in%20Dubai%20real%20estate.%20Could%20you%20contact%20me%3F";

    return (
        <button
            className="fixed inset-x-0 bottom-0 mx-auto w-full max-w-md bg-green-500 text-white py-3 px-4 flex items-center justify-between rounded"
            onClick={() => window.open(whatsappUrl, '_blank')}
        >
            <div className="flex flex-col items-start justify-center">
                <div className="font-bold">
                    Ready for Details or Insight? Whatsapp.
                </div>
                <div className="text-sm">
                    RealtyScout - Estate Analytics Dubai
                </div>
            </div>
            <FaWhatsapp size="28" /> {/* Adjusted WhatsApp icon size */}
        </button>

    )
}