
import { useEffect, useState } from 'react';
import { Radio, RadioGroup } from 'rizzui';
import { initialFilterState, possibleValues } from './filterStateAtom';

const filter_name = 'project_status';

export default function StatusFilter({ state, onValueChange }) {
  const [value, setValue] = useState(state);
  useEffect(() => {
    setValue(state); // Update local state when prop changes
  }, [state]);
  useEffect(() => {
    if (onValueChange) {
      onValueChange(filter_name, value);
    }
  }, [value, onValueChange]);

  return (
    <div className="border border-gray-300 p-4 rounded">
      <p>Project Status</p>
      <div className="mt-4">
        <RadioGroup
          value={value}
          setValue={setValue}
          className="grid grid-cols-2 gap-4"
        >
          {possibleValues[filter_name].map((status,index) => (
            <Radio
              key={status}
              label={
                <div className={`flex items-center justify-between text-gray-800 ${index === 2 ? 'col-span-2' : ''}`}>
                     <span className="ml-2">{status}</span>
                </div>
            }
              labelClassName="w-full"
              name="status_type"
              value={status}
            />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}
