import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from "./shared/header"
import { FiCheckCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import CTADownButton from './shared/cta_down_button';
export default function Landing() {
  const [activeBlock, setActiveBlock] = useState(0); // Default active block

  const handleClick = (ind) => {
    setActiveBlock(ind);
  };
  const options =
    [
      {
        title: "Discover Projects on the Map",
        text: "Explore every corner of Dubai with our interactive map",
        ind: 0,
        btnName: "Explore Map View",
        path: "mapview"
      },
      {
        title: "Advanced Project Search",
        text: "Filter through our extensive database using detailed criteria",
        ind: 1,
        isActive: true, // Default active
        btnName: "Start Your Search",
        path: "tableview"
      },
      {
        title: "July 2024 Real Estate Insights",
        text: "Get in-depth insights into Dubai's real estate trends with our latest report",
        ind: 2,
        btnName: "Read the Report",
        path: "reportview"
      }
    ]
  function ContentBlock({ title, text, onClick, isActive = false }) {
    return (
      <div onClick={onClick} className={`cursor-pointer bg-gray-0 p-3 dark:bg-gray-50 lg:p-7 mb-2 mr-4 ml-4 ${isActive ? 'border-2 border-blue-500' : 'border border-gray-300'} rounded-lg`}>
        <div className="flex justify-between items-center">
          <h4 className="text-xl font-medium text-gray-900 mb-2">{title}</h4>
          {isActive && <FiCheckCircle className="text-blue-500" />} {/* Show the blue icon if isActive is true */}
        </div>
        <p className="" >{text}</p>
      </div>
    );
  }
  const navigate = useNavigate();
  const goTo = (path) => {
    navigate("/" + path); // Navigates to MapView page
  };
  return (
    <>
      <Helmet>
        <title>RealtyScout - Get Started with our App</title>
        <meta name="description" content="Discover RealtyScout: Your gateway to navigating Dubai's real estate with ease. Explore in-depth analysis and find your ideal property today." />
        {/* Add more tags as needed */}
      </Helmet>


      <main className="relative flex w-full flex-col max-w-[500px] min-h-screen mx-auto">
        <div className="absolute bg-no-repeat bg-cover bg-center w-full h-full"></div>
        <div className="relative z-10">

          <Header></Header>
          {/* Use flex-grow to ensure this div takes up all available space */}
          <div className="flex-grow flex flex-col">
            <h3 className="text-xl font-bold text-gray-700 p-3 mt-3 text-center">Get Started with RealtyScout App:</h3>
            <div className="pt-4">
              {options.map((block, index) => (
                <ContentBlock
                  key={index}
                  title={block.title}
                  text={block.text}
                  onClick={() => handleClick(block.ind)}
                  isActive={activeBlock === block.ind}

                />
              ))}
            </div>
            <div className="flex justify-center mt-4 mb-4">
              <button className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-700" onClick={() => goTo(options[activeBlock].path)}>
                {options[activeBlock].btnName}
              </button>
            </div>
          </div>
        </div>
        <CTADownButton />
      </main>
    </>

  );
}
