import React, { useState} from 'react';
import '../styles/drawer.css';
import { Radio, RadioGroup } from 'rizzui';
import getSubsetExcludingFutureMonths from '../utils/month-report';

export default function MonthPickerDrawer({ onDatePicked, currentMonth, onClose }) {
    const [value, setValue] = useState(currentMonth);
      
      // Example usage
      const subsetWithoutCurrentMonth =  getSubsetExcludingFutureMonths ();

    return (
        <div className="relative flex h-full w-full flex-col bg-white px-5 py-3.5 dark:bg-gray-50">
            <div className="mx-5 mb-6 flex items-center justify-between border-b border-muted px-4 pb-4">
                <h2 className="text-[20px] font-bold">Use Filters</h2>
                <button className="flex items-center bg-gray-500 text-white py-1 px-2 hover:bg-blue-700 rounded-md"
                    onClick={() => { onClose(); }}>
                    Close
                </button>
            </div>
            <div className="relative flex h-full w-full overflow-y-auto flex-col bg-white dark:bg-gray-50">
                <div className="@container">
                    <div className="border border-gray-300 p-4 rounded">
                        <p>Choose Month</p>
                        <div className="mt-4">
                            <RadioGroup
                                value={value}
                                setValue={setValue}
                                className="grid grid-cols-2 gap-4"
                            >
                                {subsetWithoutCurrentMonth.map(({ key, name }) => (
                                    <Radio
                                        key={key}
                                        label={
                                            <div className="flex items-center justify-between text-gray-800">
                                                <span className="ml-2">{name}</span>
                                            </div>
                                        }
                                        labelClassName="w-full"
                                        name="month"
                                        value={key}
                                    />
                                ))}
                            </RadioGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center gap-3 bg-white pt-2 dark:bg-gray-50">

                <button className="w-full bg-blue-500 text-white py-1 px-2 hover:bg-blue-700 rounded-md"
                    onClick={() => { onDatePicked(value); }}>
                    Show results
                </button>
            </div>
        </div>
    );
}