export function filterGeoJSON(data, filters) {
  const filteredFeatures = data.features.filter(feature => {
    const props = feature.properties;
    if (props === null) {
      return false;
    }
    // Filter for project_status
    const matchesProjectStatus = filters.project_status === 'Any' || props.project_status === filters.project_status;

    // Filter for property_type
    const matchesPropertyType = filters.property_type === 'Any' || props.prop_type_str.includes(filters.property_type);

    // Assuming bedrooms can be multiple values, check if any match
    const matchesBedrooms = filters.bedrooms === 'Any' || props.bedrooms_str.includes(filters.bedrooms);

    // Assuming price_category directly maps to qntl5
    const matchesPriceCategory = filters.price_category === 'Any' || filters.price_category === props.qntl5.toString();

    return matchesProjectStatus && matchesPropertyType && matchesBedrooms && matchesPriceCategory;
  });

  return {
    ...data,
    features: filteredFeatures,
  };
}

