import { Avatar } from 'rizzui';
import { formatNumber } from '../utils/format-number';

export default function TopTable({ data }) {
  return (
<div className="w-full rounded-[10px] border border-gray-300 px-4 py-4 min-w-[300px]">
  <p className="text-gray-900 font-semibold text-center">{data.title}</p>
  <div className="mt-4">
    <div className="mb-4 flex items-center justify-between border-b border-muted pb-4 last:mb-0 last:border-0 last:pb-0">
      {data.column_names.map((columnName, index) => (
        // Updated width for the first column to 50% and the others to 25%
        <p key={index} className={`text-sm text-gray-600 dark:text-gray-700 ${index === 0 ? 'w-1/2' : 'w-1/4 text-center'}`}> {columnName}</p>
      ))}
    </div>
    {data.table_data.map((entry, index) => (
      <div
        key={index}
        className="items-center mb-4 flex justify-between border-b border-muted pb-4 last:mb-0 last:border-0 last:pb-0"
      >
        {/* Adjusting the first column to occupy 50% of the space */}
        <div className="flex items-center justify-start gap-2 w-1/2">
          {/* Optionally include the Avatar component */}
          {/* <Avatar
            name={entry.name}
            className="rounded-lg text-white"
            size="sm"
          /> */}
          <p className="font-lexend text-sm font-medium text-gray-900 dark:text-gray-700 flex-1">
            {entry.name}
          </p>
        </div>
        {/* Mapping over values to create the next two columns, each taking 25% */}
        {entry.values.map((value, valueIndex) => (
          <p key={valueIndex} className="w-1/4 text-sm text-gray-600 dark:text-gray-700 flex-1  text-center"> {formatNumber(value)}</p>
        ))}
      </div>
    ))}
  </div>
</div>

  )

  //   <div className="w-full rounded-[10px] border border-gray-300 px-4 py-4 min-w-[300px]">
  //     <p className="text-gray-900 font-semibold text-center ">{data.title}</p>
  //     <div className="mt-4">
  //       <div className="mb-4 flex items-center justify-between border-b border-muted pb-4 last:mb-0 last:border-0 last:pb-0">
  //         {data.column_names.map((columnName, index) => (
  //           <p key={index} className="text-sm text-gray-600 dark:text-gray-700"> {columnName}</p>
  //         ))}
  //       </div>
  //       {data.table_data.map((entry, index) => (
  //         <div
  //           key={index}
  //           className="mb-4 flex items-center justify-between border-b border-muted pb-4 last:mb-0 last:border-0 last:pb-0"
  //         >
  //           <div className="flex items-center justify-start gap-2">
  //             {/* <Avatar
  //               name={entry.name}
  //               className="rounded-lg text-white"
  //               size="sm"
  //             /> */}
  //             <p className="font-lexend text-sm font-medium text-gray-900 dark:text-gray-700">
  //               {entry.name}
  //             </p>
  //           </div>
  //           {entry.values.map((value, index) => (
  //           <p key={index} className="text-sm text-gray-600 dark:text-gray-700"> {value}</p>
  //         ))}
  //         </div>
  //       ))}
  //     </div>
  //   </div>
  // );
}
