import React from 'react';
import { PiBank, PiCurrencyCircleDollar, PiArrowDownRight, PiArrowUpRight } from 'react-icons/pi';
import { RxSize } from "react-icons/rx";
import { MdOutlinePriceChange } from "react-icons/md";
import { MdOutlinePriceCheck } from "react-icons/md";


export default function TotalCard({ data }) {
  // Function to return the correct icon component based on the 'icon' prop string
  const Icon = () => {
    switch (data.icon) {
      case 'PiBank':
        return <PiBank className="h-auto w-[30px]" />;
      case 'PiCurrencyCircleDollar':
        return <PiCurrencyCircleDollar className="h-auto w-[30px]" />;
      case 'RxSize':
        return <RxSize className="h-auto w-[30px]" />;
      case 'MdOutlinePriceChange':
        return <MdOutlinePriceChange className="h-auto w-[30px]" />;
      case 'MdOutlinePriceCheck':
        return <MdOutlinePriceCheck className="h-auto w-[30px]" />;
      default:
        return <PiBank className="h-auto w-[30px]" />; // Default icon
    }
  };

  return (
    <div className="w-full rounded-[10px] border border-gray-300 px-6 py-7 min-w-[300 px]">
      <div className="mb-4 flex items-center gap-5">
        <span style={{ backgroundColor: data.iconWrapperFill }} className="flex rounded-[14px] p-2.5 text-gray-0 dark:text-gray-900">
          <Icon />
        </span>
        <div className="space-y-2">
          <p className="text-gray-900 font-semibold ">{data.title}</p>
          <p className="font-lexend text-lg font-semibold text-gray-900 2xl:text-[20px] 3xl:text-[22px] dark:text-gray-700">{data.amount}</p>
        </div>
      </div>
      <div className="flex items-center gap-1.5">
        <div className={`flex items-center gap-1 ${data.increased ? 'text-green-dark' : 'text-red-dark'}`}>
          <span className="flex rounded-full px-2.5 py-1.5 ${data.increased? 'bg-green-lighter/70 dark:bg-green-dark/30' : 'bg-red-lighter/70 dark:bg-red-dark/30">
            {data.increased ? <PiArrowUpRight className="h-auto w-4" /> : <PiArrowDownRight className="h-auto w-4" />}
          </span>
          <span className="font-medium leading-none">{data.increased ? '+' : '-'}{data.percentage}</span>
        </div>
        <span className="truncate leading-none text-gray-500">{data.increased ? 'Increased' : 'Decreased'} to previous month</span>
      </div>
    </div>
  );
}
